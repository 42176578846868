<template>
    <div class="w-100 blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
        <SupplierPaymentSuccess187 v-if="supplierId == 187"></SupplierPaymentSuccess187>
        <SupplierPaymentSuccess185 v-else-if="supplierId == 185"></SupplierPaymentSuccess185>
        <SupplierPaymentSuccess999 v-else-if="supplierId == 999"></SupplierPaymentSuccess999>
        <SupplierPaymentSuccess194 v-else-if="supplierId == 194"></SupplierPaymentSuccess194>
        <SupplierPaymentSuccess186 v-else-if="supplierId == 186"></SupplierPaymentSuccess186>
        <SupplierPaymentSuccess193 v-else-if="supplierId == 193"></SupplierPaymentSuccess193>
        <SupplierPaymentSuccess212 v-else-if="supplierId == 212"></SupplierPaymentSuccess212>
        <SupplierPaymentSuccess213 v-else-if="supplierId == 213"></SupplierPaymentSuccess213>
        <SupplierPaymentSuccess1013 v-else-if="supplierId == 1013"></SupplierPaymentSuccess1013>
        <SupplierPaymentSuccess1019 v-else-if="supplierId == 1019"></SupplierPaymentSuccess1019>
        <main v-else class="supplier-payment-success page_bg_grey">
            <div class="text-center">Payment Success Page, sorry, we are developing this page yet...</div>
        </main>

        <loading :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </div>
</template>

<script>

    import {mapState, mapActions} from 'vuex'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import SupplierPaymentSuccess187 from '../components/supplier_payments/187/SupplierPaymentSuccess187'
    import SupplierPaymentSuccess185 from '../components/supplier_payments/185/SupplierPaymentSuccess185'
    import SupplierPaymentSuccess999 from '../components/supplier_payments/999/SupplierPaymentSuccess999'
    import SupplierPaymentSuccess194 from '../components/supplier_payments/194/SupplierPaymentSuccess194'
    import SupplierPaymentSuccess186 from '../components/supplier_payments/186/SupplierPaymentSuccess186'
    import SupplierPaymentSuccess193 from '../components/supplier_payments/193/SupplierPaymentSuccess193'
    import SupplierPaymentSuccess212 from '../components/supplier_payments/212/SupplierPaymentSuccess212'
    import SupplierPaymentSuccess213 from '../components/supplier_payments/213/SupplierPaymentSuccess213'
    import SupplierPaymentSuccess1013 from '../components/supplier_payments/1013/SupplierPaymentSuccess1013'
    import SupplierPaymentSuccess1019 from '../components/supplier_payments/1019/SupplierPaymentSuccess1019'
    export default {
        name: 'SupplierPaymentSuccess',
        components: {
            Loading,
            SupplierPaymentSuccess187,
            SupplierPaymentSuccess185,
            SupplierPaymentSuccess999,
            SupplierPaymentSuccess194,
            SupplierPaymentSuccess186,
            SupplierPaymentSuccess193,
            SupplierPaymentSuccess212,
            SupplierPaymentSuccess213,
            SupplierPaymentSuccess1013,
            SupplierPaymentSuccess1019
        },
        // data: () => {
        //     return {
        //         loading: false
        //     }
        // },
        computed: {
            ...mapState({
                current_payment_data: state => state.supplier_payment.current_payment_data,
                current_transaction_id: state => state.supplier_payment.current_transaction_id,
                getTransactionReceiptInProgress: state => state.supplier_payment.getTransactionReceiptInProgress,
                loading: state => state.supplier.loading,
                transactions_receipts: state => state.supplier_payment.transactions_receipts
            }),
            supplierId() {
                return this.$route.params.supplier_id
            },
            paymentData() {
                let paymentData = this.current_payment_data && (this.supplierId in this.current_payment_data) ? this.current_payment_data[this.supplierId] : null

                return paymentData
            },
            transactionId() {
                let transactionId = this.$route.params.transaction_id || null;
                if (transactionId == null && this.current_transaction_id && this.supplierId in this.current_transaction_id) {
                    transactionId = this.current_transaction_id[this.supplierId];
                }
                return transactionId;
            }
        },
        created() {
            if (this.transactionId) {
                this.getTransactionReceipt({transaction_id: this.transactionId})
                this.getSupplier({'supplier_id': this.supplierId})
            } else {
                this.$router.push({name: 'SupplierPayment', params: {supplier_id: this.supplierId, transaction_id: this.transactionId}})
            }
        },
        methods: {
            ...mapActions('supplier', {
                getSupplier: 'getSupplier'
            }),
            ...mapActions('supplier_payment', {
                getTransactionReceipt: 'getTransactionReceipt',
            }),
        }
    }
</script>