<template>
  <main
    class="supplier-payment-confirmation supplier-payment-confirmation-999 page_bg_grey"
    v-if="!paymentDetails"
  >
    <section id="print">
      <section class="payment-caption-section">
        <div class="payment-caption-container">
          <div class="payment-caption-wrapper payment-product-name pt-5">
            <span>{{ $t("Load a multi-line card") }}</span>
          </div>
          <div class="payment-caption-wrapper text-center">
            <img
              class="supplier-logo"
              src="/assets/img/card.png"
            />
          </div>
          <div class="payment-caption-wrapper d-flex justify-content-center pt-5">
          </div>
        </div>
      </section>
      <section class="payment-confirmation-data-section">
        <div class="payment-confirmation-data-top-block">
          <span class="payment-confirmation-data-top-block-text color-green">{{
            $t("The multi-line card was loaded successfully")
          }}</span>
        </div>

        <div class="payment-confirmation-data">
          <div class="payment-confirmation-data-block">
            <div class="payment-confirmation-data-block-row">
              <div class="payment-confirmation-data-block-title">
                {{ $t("Details of the place of transaction") }}
              </div>
            </div>

            <div class="payment-confirmation-data-block-row">
              <div class="payment-confirmation-data-block-row-label">
                {{ $t("Date") }}:
              </div>
              <div class="payment-confirmation-data-block-row-data">
                {{contractApproval.reloaded_at.slice(0,10)}}
              </div>
            </div>

            <div class="payment-confirmation-data-block-row">
              <div class="payment-confirmation-data-block-row-label">
                {{ $t("Hour") }}:
              </div>
              <div class="payment-confirmation-data-block-row-data">
                {{contractApproval.reloaded_at.slice(10)}}
              </div>
            </div>

            <div class="payment-confirmation-data-block-row">
              <div class="payment-confirmation-data-block-row-label">
                {{ $t("Transaction approval number") }}:
              </div>
              <div class="payment-confirmation-data-block-row-data">
                {{current_transaction_id[999]}}
              </div>
            </div>
          </div>

          <div class="payment-confirmation-data-block">
            <div class="payment-confirmation-data-block-row">
              <div class="payment-confirmation-data-block-title">
                {{ $t("Invoice details") }}
              </div>
            </div>

            <div class="payment-confirmation-data-block-row">
              <div class="payment-confirmation-data-block-row-label">
                {{ $t("Multi-line card number") }}:
              </div>
              <div class="payment-confirmation-data-block-row-data">
                {{choosen_contract.transaction.card_serial}}
              </div>
            </div>

            <div class="payment-confirmation-data-block-row">
              <div class="payment-confirmation-data-block-row-label">
                {{ $t("Card Type") }}:
              </div>
              <div class="payment-confirmation-data-block-row-data">
                {{choosen_contract.display.detail[0]}}-{{choosen_contract.display.detail[1]}}
              </div>
            </div>

            <div class="payment-confirmation-data-block-row">
              <div class="payment-confirmation-data-block-row-label">
                {{ $t("Card loading") }}:
              </div>
              <div class="payment-confirmation-data-block-row-data">
                {{choosen_contract.display.detail[3]}}
              </div>
            </div>


            <div class="payment-confirmation-data-block-row red-row">
              <div class="payment-confirmation-data-block-row-label">
                {{ $t("Payment sum") }}:
              </div>
              <div class="payment-confirmation-data-block-row-data">
                {{choosen_contract.display.detail[4].slice(5)}}
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <section class="payment-confirmation-data-section">
      <div class="payment-confirmation-data">
        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row confirm-action">
            <div class="payment-confirmation-data-block-row-label print-part pointer" v-print="'#print'">
              {{ $t("For printing") }}
              <img src="/assets/img/print-light.svg" alt="">
            </div>
            <div class="payment-confirmation-data-block-row-data">
            <router-link
              :to="{
                name: 'SupplierPayment',
                params: {supplier_id: '999'}}" 
                tag="a"
                exact
            >
              <a @click="handleConnection" class="primary-btn finish-btn">
                {{ $t("Finished and closed") }}
              </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="payment-confirmation-data-bottom-block">
        <div class="payment-confirmation-data-bottom-block-text">
          <span class="">{{ $t("At the end of the payment need to give client receipt") }}</span>
        </div>
      </div>
    </section>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import appConfig from "../../../appConfig";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    Loading
  },
  name: "SupplierPaymentSuccess999",
  props: {
    msg: String,
  },
  data: () => {
    return {}
  },
  computed: {
    ...mapState({
        choosen_contract: (state) => state.chargeRavkav_module.choosen_contract,
        uid: (state) => state.chargeRavkav_module.uid, 
        loading: (state) => state.chargeRavkav_module.loading,
        current_transaction_id: (state) => state.chargeRavkav_module.current_transaction_id,
        contractApproval: (state) => state.chargeRavkav_module.contractApproval,
        paymentDetails: (state) => state.chargeRavkav_module.paymentDetails
    }),
    supplierId () {
      return this.$route.params.supplier_id
    }
  },
  created() {
  },
  methods: {
    ...mapActions('chargeRavkav_module', {
      getContractDetails:'getContractDetails',
      createTransaction:'createTransaction',
      ravkavPaymentRequest:'ravkavPaymentRequest',
      closeConnection:'closeConnection'
    }),
    handleConnection() {
      this.closeConnection()
    },
  }
};
</script>

<style lang="scss" scoped>
.supplier-payment-confirmation-999 {
  .supplier-logo {
    max-width: 120px;
  }
  .payment-product-name,
  .payment-confirmation-data-top-block-text,
  .payment-confirmation-data-bottom-block-text {
    font-size: 30px;
  }
  .payment-confirmation-data-block-row,
  .payment-success-data-block-2-columns .payment-success-data-block-row {
    font-size: 20px;
  }
}
.ltr-type {
  .payment-form {
    text-align: left;
  }
  .input-tooltip-container {
    left: unset;
    right: 0;
  }
  .payment-form-with-tooltips .payment_field_wrapper .input {
    margin-left: 0;
    margin-right: 12px;
  }
}
@media screen and (max-width: 600px) {
  .payment-confirmation-data-block-row-label,
  .payment-confirmation-data-block-row-data,
  .payment-success-data-block-2-columns .payment-success-data-block-row-label,
  .payment-success-data-block-2-columns .payment-success-data-block-row-data {
    display: block;
    width: 100%;
  }
}

.confirm-action {
  align-items: center;
  display: flex;
  border-top: 1px solid #b2bbc2;
  border-bottom: 1px solid #b2bbc2;
  padding: 20px 0;
  margin-top: 30px;
  .print-part {
    align-items: center;
    display: flex;
    img {
      width: 50px;
      margin: 0 10px;
    }
  }
}
</style>
