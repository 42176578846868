<template>
  <main
    class="supplier-payment-confirmation supplier-payment-confirmation-186 page_bg_grey"
  >
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("Purchase a power charging code") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/n-logo.png"
          />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-center pt-5">
        </div>
      </div>
    </section>

    <section class="payment-confirmation-data-section">
      <div class="payment-confirmation-data-top-block">
        <span class="payment-confirmation-data-top-block-text color-green">{{
          $t("The multi-line card was loaded successfully")
        }}</span>
      </div>

      <div class="payment-confirmation-data">
        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-title">
              {{ $t("Details of the place of transaction") }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Business name") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ businessName }}</div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Business address") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              {{ userAddress }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Date") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              {{ transactionDateString }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Hour") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ transactionTimeString }}</div>
          </div>

        </div>

        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-title">
              {{ $t("Invoice details") }}
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Transaction approval number") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data"></div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Supplier ID") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data"></div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Contract number") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ contractNumber }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Customer's name") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ name }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Address") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ address }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Purchase amount") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ real_amount }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Total net amount") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ net_amount }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("KWh") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ ktwh }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Amount to debts") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ amount_of_debts }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Total charges - additional credits") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">{{ total_charges_or_additional_credits }}</div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Amount of invoices") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">

            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Service fee") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">

            </div>
          </div>

          <div class="payment-confirmation-data-block-row red-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("Payment sum") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">

            </div>
          </div>

          <div class="loading-code">
            {{ $t("Loading code") }}:
          </div>
          <div class="payment-confirmation-data-block-row confirm-action">
            <div class="payment-confirmation-data-block-row-label print-part">
              {{ $t("For printing") }}
              <img src="/assets/img/print-light.svg" alt="">
            </div>
            <div class="payment-confirmation-data-block-row-data">
              <button class="primary-btn finish-btn">
                {{ $t("Finished and closed") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="payment-confirmation-data-bottom-block">
        <div class="payment-confirmation-data-bottom-block-text">
          <span class="">{{ $t("At the end of the payment need to give client receipt") }}</span>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import appConfig from "../../../appConfig";

export default {
  name: "SupplierPaymentConfirmation186",
  props: {
    msg: String,
  },
  data() {
    return {
      currentDate: new Date(),
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      current_payment_data: (state) =>
        state.supplier_payment.current_payment_data,
      supplier_items: (state) => state.supplier.supplier_items,
      suppliers: (state) => state.supplier.suppliers,
      user_details: (state) =>
        state.account.user_details &&
        state.account.user_details.hasOwnProperty("details")
          ? state.account.user_details.details
          : null,
      transactions_receipts: state => state.supplier_payment.transactions_receipts
    }),
    supplierId() {
      return this.$route.params.supplier_id;
    },
    supplierData() {
      let supplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.supplierId) {
              supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
              break;
            }
          }
        }
      }

      let supplierDataFromSuppliersData = null;
      if (this.suppliers && this.supplierId in this.suppliers) {
        supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] };
      }

      let supplierData = supplierDataFromSuppliersData
        ? supplierDataFromSuppliersData
        : supplierDataFromSuppliersItemsData;
      return supplierData ? supplierData : null;
    },
    paymentData() {
      let paymentData =
        this.current_payment_data &&
        this.supplierId in this.current_payment_data
          ? this.current_payment_data[this.supplierId]
          : null;

      return paymentData;
    },
    contractNumber() {
      return this.paymentData && this.paymentData.hasOwnProperty('contractNumber') ? this.paymentData.contractNumber : ''
    },
    sumToPay() {
      return this.paymentData && this.paymentData.hasOwnProperty("sumToPay")
        ? this.paymentData.sumToPay
        : "";
    },
    finalCommission() {
      return this.paymentData &&
        this.paymentData.hasOwnProperty("finalCommission")
        ? this.paymentData.finalCommission
        : "";
    },
    price() {
      return this.paymentData && this.paymentData.hasOwnProperty("price")
        ? this.paymentData.price
        : "";
    },
    address() {
      return this.paymentData && this.paymentData.hasOwnProperty('address') ? this.paymentData.address : ''
    },
    invoice() {
      return this.paymentData && this.paymentData.hasOwnProperty('invoice') ? this.paymentData.invoice : ''
    },
    name() {
      return this.paymentData && this.paymentData.hasOwnProperty('name') ? this.paymentData.name : ''
    },
    last_sale() {
      return this.paymentData && this.paymentData.hasOwnProperty('last_sale') ? this.paymentData.last_sale : ''
    },
    counter_number() {
      return this.paymentData && this.paymentData.hasOwnProperty('counter_number') ? this.paymentData.counter_number : ''
    },
    real_amount() {
      return this.paymentData && this.paymentData.hasOwnProperty('real_amount') ? this.paymentData.real_amount : ''
    },
    net_amount() {
      return this.paymentData && this.paymentData.hasOwnProperty('net_amount') ? this.paymentData.net_amount : ''
    },
    ktwh() {
      return this.paymentData && this.paymentData.hasOwnProperty('ktwh') ? this.paymentData.ktwh : ''
    },
    amount_of_debts() {
      return this.paymentData && this.paymentData.hasOwnProperty('amount_of_debts') ? this.paymentData.amount_of_debts : ''
    },
    total_charges_or_additional_credits() {
      return this.paymentData && this.paymentData.hasOwnProperty('total_charges_or_additional_credits') ? this.paymentData.total_charges_or_additional_credits : ''
    },
    itemId() {
      return this.paymentData && this.paymentData.hasOwnProperty("itemId")
        ? this.paymentData.itemId
        : "";
    },
    phoneNumber() {
      return this.paymentData && this.paymentData.hasOwnProperty("phoneNumber")
        ? this.paymentData.phoneNumber
        : "";
    },
    businessName() {
      return this.user_details && this.user_details.businessName
        ? this.user_details.businessName
        : "";
    },
    userAddress() {
      return this.user_details && this.user_details.address && this.user_details.address.address
        ? this.user_details.address.address
        : "";
    },
    transactionReceiptData() {
      let transactionReceiptData = null;
      if (this.transactions_receipts && this.transactionId in this.transactions_receipts) {
        transactionReceiptData = {...this.transactions_receipts[this.transactionId]}
      }

      return transactionReceiptData ? transactionReceiptData : null
    },
    transactionDateTimeString() {
      let transactionDateTimeString = null

      if (this.transactionReceiptData && this.transactionReceiptData.hasOwnProperty('transDateTime')
          && this.transactionReceiptData.transDateTime) {
        transactionDateTimeString = this.transactionReceiptData.transDateTime
      }

      return transactionDateTimeString
    },
    transactionDateTime() {
      let transactionDateTime = null

      if (this.transactionDateTimeString) {
        transactionDateTime = new Date(Date.parse(this.transactionDateTimeString))
      }

      return transactionDateTime
    },
    transactionDateString() {
      let transactionDateString = ''

      if (this.transactionDateTime) {
        transactionDateString = this.transactionDateTime.getDate().toString().padStart(2, '0') + '/' + (this.transactionDateTime.getMonth() + 1).toString().padStart(2, '0') + '/' + this.transactionDateTime.getFullYear()
      }

      return transactionDateString
    },
    transactionTimeString() {
      let transactionTimeString = ''

      if (this.transactionDateTime) {
        transactionTimeString = this.transactionDateTime.getHours().toString().padStart(2, '0') + ":" + this.transactionDateTime.getMinutes().toString().padStart(2, '0') + ":" + this.transactionDateTime.getSeconds().toString().padStart(2, '0')
      }

      return transactionTimeString
    },
    currentDateString() {
      let currentDateString =
        this.currentDate.getDate().toString().padStart(2, "0") +
        "/" +
        (this.currentDate.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        this.currentDate.getFullYear();

      return currentDateString;
    },
    currentTimeString() {
      let currentTimeString =
        this.currentDate.getHours().toString().padStart(2, "0") +
        ":" +
        this.currentDate.getMinutes().toString().padStart(2, "0") +
        ":" +
        this.currentDate.getSeconds().toString().padStart(2, "0");

      return currentTimeString;
    },
  },
  created() {},
  methods: {
    ...mapActions("supplier_payment", {
      supplierPayment: "supplierPayment",
    }),
    getApiURL() {
      return appConfig.apiUrl;
    }
  },
  updated: function () {},
};
</script>

<style lang="scss" scoped>
.supplier-payment-confirmation-186 {
  .payment-caption-container {
    align-items: center;
    .payment-caption-wrapper {
      margin-top: 0;
    }
  }
  .supplier-logo {
    max-width: 100px;
    min-width: unset;
  }
  .payment-product-name,
  .payment-confirmation-data-top-block-text,
  .payment-confirmation-data-bottom-block-text {
    font-size: 30px;
  }
  .payment-confirmation-data-block-row,
  .payment-success-data-block-2-columns .payment-success-data-block-row {
    font-size: 20px;
  }
  .payment-confirmation-data {
    max-width: 600px;
    padding: 0;
  }
  .payment-confirmation-data-block-row-label {
    min-width: 430px;
  }
  .payment-confirmation-data-block-row-data {
    width: unset;
  }
}
.ltr-type {
  .payment-form {
    text-align: left;
  }
  .input-tooltip-container {
    left: unset;
    right: 0;
  }
  .payment-form-with-tooltips .payment_field_wrapper .input {
    margin-left: 0;
    margin-right: 12px;
  }
}
@media screen and (max-width: 600px) {
  .payment-confirmation-data-block-row-label,
  .payment-confirmation-data-block-row-data,
  .payment-success-data-block-2-columns .payment-success-data-block-row-label,
  .payment-success-data-block-2-columns .payment-success-data-block-row-data {
    display: block;
    width: 100%;
  }
}

.confirm-action {
  align-items: center;
  display: flex;
  border-top: 1px solid #b2bbc2;
  border-bottom: 1px solid #b2bbc2;
  padding: 20px 0;
  margin-top: 30px;
  .print-part {
    align-items: center;
    display: flex;
    img {
      width: 50px;
      margin: 0 10px;
    }

  }
}
.finish-btn {
    padding: 0 20px;
}
</style>
